import { z } from 'zod';

import { getConfig } from '../../config/env';

const { wsConfig } = getConfig();
const authApiUrl = wsConfig.authApiDomain;

const identitySchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string().nullable(),
  password: z.string(),
});

const identityRoleSchema = z.object({
  id: z.string(),
  isParent: z.boolean(),
});

const createIdentityResponseSchema = z.object({
  identityCreate: z.object({
    firebaseCustomToken: z.string(),
  }),
});

export type Identity = z.infer<typeof identitySchema>;
export type identityRole = z.infer<typeof identityRoleSchema>;

const createIdentityMutation = `
  mutation IdentityCreate($identity: IdentityCreateInput!) {
    identityCreate(identity: $identity) {
      firebaseCustomToken
    }
  }
`;

const identityReadQuery = `
query Query($identityId: String!) {
  identity(id: $identityId) {
    id,
    isParent
  }
}`;

export const createIdentity = async (
  identityCreate: Identity
): Promise<string> => {
  const variables = { identity: identityCreate };

  const response = await fetch(authApiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: createIdentityMutation,
      variables,
    }),
  });

  const responseBody = await response.json();

  if (responseBody.errors) {
    throw new Error(`Errors: ${JSON.stringify(responseBody.errors)}`);
  }

  const responseData = createIdentityResponseSchema.parse(responseBody.data);
  return responseData.identityCreate.firebaseCustomToken;
};

export const readIdentity = async (identityId: string, idToken: string) => {
  const variables = { identityId };

  const response = await fetch(authApiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({
      query: identityReadQuery,
      variables,
    }),
  });

  const responseBody = await response.json();

  if (responseBody.errors) {
    throw new Error(`Errors: ${JSON.stringify(responseBody.errors)}`);
  }

  const responseData = identityRoleSchema.parse(responseBody.data.identity);

  return responseData;
};
